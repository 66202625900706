/*Fade*/
.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: 500ms linear;
}

.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.fade-leave {
  opacity: 1;
}
.fade-leave.fade-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

/*Fade in from dx*/
.fadeInslideFromDx-appear,
.fadeInslideFromDx-enter {
  opacity: 0;
  transform: translateX(400px);
}

.fadeInslideFromDx-appear-active,
.fadeInslideFromDx-enter.fadeInslideFromDx-enter-active {
  opacity: 1;
  transform: translateX(40px);
  transition: 500ms linear;
}

.fadeInslideFromDx-exit {
  opacity: 1;
  transform: translateX(0);
}
.fadeInslideFromDx-exit.fadeInslideFromDx-exit-active {
  transform: translateX(-400px);
  opacity: 0;
  transition: 500ms linear;
}
.fadeInslideFromDx-leave {
  opacity: 1;
  transform: translateX(0);
}
.fadeInslideFromDx-leave.fadeInslideFromDx-leave-active {
  transform: translateX(-400px);
  opacity: 0;
  transition: 500ms linear;
}

/*Fade in slide from SX*/
.fadeInslideFromSx-appear,
.fadeInslideFromSx-enter {
  opacity: 0;
  transform: translateX(-400px);
}

.fadeInslideFromSx-appear-active,
.fadeInslideFromSx-enter.fadeInslideFromSx-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: 500ms linear;
}

.fadeInslideFromSx-exit {
  opacity: 1;
  transform: translateX(0);
}
.fadeInslideFromSx-exit.fadeInslideFromSx-exit-active {
  transform: translateX(400px);
  opacity: 0;
  transition: 500ms linear;
}
.fadeInslideFromSx-leave {
  opacity: 1;
  transform: translateX(0);
}
.fadeInslideFromSx-leave.fadeInslideFromSx-leave-active {
  transform: translateX(400px);
  opacity: 0;
  transition: 500ms linear;
}
