@import url(//db.onlinewebfonts.com/c/0a6ee448d1bd65c56f6cf256a7c6f20a?family=Bahnschrift);
.html,body {
  font-family: "Bahnschrift", sans-serif!important;
}

.headerContainer {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100vw;
  padding-top: 0vh;
  height: 5vh;
  background-color: white;
  min-height: 50px;
  /* box-shadow: 0 4px 12px rgba(0,0,0,0.4) ; */
  border-bottom: 1px solid #ddd;
  z-index: 1000;
  transition: all 1s ease-in;
}
.headerSearchButton {
  display: flex;
  background-image: url("../img/home/search-icon-png-9985.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
  padding-left: 5vw;
  cursor: pointer;
}
.headerRunvisor {
  display: flex;
  justify-content: center;
  width: 60vw;
  cursor: pointer;
}
.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
  background-color: black !important;
}
.runvisor {
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 5vh;
  cursor: pointer;
  padding-left: 6vw;
}
.backgroundHomeImage {
  position: relative;
  background-image: url("../img/landing/running2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 50vh;
  width: 100vw;
  padding: 0 0 0 0;
  z-index: -10;
}
.footerGlobalContainer {
  display: flex;
  position: fixed;
  top: 90vh;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: calc(100vw - 3px);
  padding-top: 0vh;
  border: 1.5px solid black;
  height: 6vh;
  background-color: white;
  min-height: 30px;
}
.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footerText {
  color: #999999;
  font-size: 10px;
}
.footerHomeImage {
  display: flex;
  background-image: url("../img/home/iconfinder_house-home-main-menu-start-building_3643769.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 4vh;
  width: 4vh;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 40px;
  max-height: 40px;
}
.footerCorseImage {
  display: flex;
  background-image: url("../img/home/iconfinder_21_2739100.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 4vh;
  width: 4vh;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 40px;
  max-height: 40px;
}
.footerProfiloImage {
  display: flex;
  background-image: url("../img/home/iconfinder_header-account-image-line_1540176.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 4vh;
  width: 4vh;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 40px;
  max-height: 40px;
}
.searchBoxContainerGlobal {
  display: flex;
  position: fixed;
  top: 85vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3.2vh;
  width: 100vw;
  cursor: pointer;
}
.searchBoxContainer {
  display: flex;
  position: fixed;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  width: 56vw;
  height: 3.2vh;
  max-width: 600px;
}
.searchBox {
  display: flex;
  font-weight: bold;
  padding-left: 10vw;
  padding-right: 8vw;
  align-items: center;
  align-content: center;
  align-self: center;
  cursor: pointer;
}
.searchBoxArrow {
  display: flex;
  background-image: url("../img/home/iconfinder_arrow-down-01_186411.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 2vh;
  width: 2vh;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 40px;
  max-height: 40px;
}
.carousel9 .control-dots .dot {
  opacity: 0.6 !important;
  background: black !important;
}
.carousel9 .control-dots .dot.selected {
  opacity: 1 !important;
}
.carousel9 .control-dots {
  position: relative !important;
  padding-top: 4vh !important;
}
.carousel9 .slider-wrapper {
  border-radius: 15px;
  max-width: 86vw;
}
.slick-dots {
  bottom: -36px !important;
}
.slick-dots li {
  margin: 0 !important;
}
.slick-dots2 {
  position: absolute;
  bottom: -36px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  /* margin-top: 2vh; */
}
.slick-dots2 li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  /* margin: 0 5px; */
  padding: 0;
  cursor: pointer;
}
.slick-dots2 li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots2 li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots2 li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots2 li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\2022";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: white;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots2 li.slick-active button:before {
  color: white;
  opacity: 0.75;
}
.slick-dots3 {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  /* margin-top: 2vh; */
}
.slick-dots3 li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  /* margin: 0 5px; */
  padding: 0;
  cursor: pointer;
}
.slick-dots3 li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 0px;
  cursor: pointer;
}
.slick-dots3 li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots3 li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots3 li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\2022";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots3 li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.selectRunTypeContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: white;
  justify-content: space-evenly;
  padding-top: 3.5vh;
}
.selectRunText {
  color: black;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  width: 100vw;
  text-align: center;
  padding-bottom: 1.5vh;
}
.selectRunType {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: scroll;
}
.runTypeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  align-items: center;
  padding-left: 0vw;
  padding-right: 0vw;
}
.runType {
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /* height: 30.50vw; */
  width: 30.50vw;
  cursor: pointer;
  object-fit: fill;
  justify-content: space-around;
  padding-bottom: 1.5vh;
}
.runTypeText {
  display: flex;
  justify-content: center;
  padding-bottom: 1.5vh;
  cursor: pointer;
  width: 93px;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}
.proposedRunContainer {
  position: relative;
}
.mapContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 49.5vh;
  margin-top: 4.5vh;
  flex-direction: column;
  padding-top:4vh;
}
.sliderTitle {
  font-size: 20px;
  font-weight: bold;
  /* width: 100vw; */
  text-align: center;
  color: black;
  padding-top: 32px;
  padding-left: 0px;
  /* padding-bottom: 1.5vh; */
}
.singleSlideImage {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 20vh;
  width: 18vh;
  padding: 0 0 0 0;
  max-width: 600px;
  max-height: 400px;
}
.slideContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 0vw;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(248, 248, 248);
}
.slideTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 1vh;
  /*height: 18vh;*/
  /*width: 18vh;*/

  /*max-width: 600px;*/
  /*max-height: 400px;*/
}
.slideDate {
  font-size: 18px;
  font-weight: lighter;
}
.slideRunName {
  font-size: 18px;
  font-weight: bold;
  padding-top: 0vh;
}
.runDistanceDifferenceContainer {
  display: flex;
  flex-direction: column;
}
.slideRunDistance {
  font-size: 12px;
  padding-top: 0vh;
}
.slideRunDifference {
  font-size: 12px;
}
.callToAction {
  background-color: black;
  height: 80vh;
  width: 100vw;
  color: white;
}
.iconAndFeedbackContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-right: 1vh;
}
.iconContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.addToCalendar {
  position: relative;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  height: 25px;
  width: 25px;
  padding: 0 2 0 0;
}
.modify {
  position: relative;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  height: 25px;
  width: 25px;
  padding: 0 0 0 0;
}
.feedbackContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.feedbackStyle {
  height: 10px;
  width: 10px;
  background-color: lightgrey;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
}
.menuItem {
  font-size: 16px;
  padding-left: 4vw;
}
.menuItem:hover {
  color: rgb(148, 214, 10);
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  display: flex;
}

.headerItemContainer {
  width: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0vh;
  position: absolute;
  top: 50px;
  max-height: 100vh;
  -webkit-transition: all 1s ease;
}
.change.headerItemContainer {
  max-height: 0;
}

.noShadow.headerContainer {
  box-shadow: none;
}
.textShrink {
  width: 85vw;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

footer {
  max-height: 80;
}
.showFooter.footer {
  max-height: 0;
}
