.highlighted {
  color: rgb(148, 214, 10);
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  display: flex;
}
.datePicker {
  height: 32px;
  width: 86vw;
  border: 1px solid gainsboro;
  border-radius: 5px;
}
