#root {
  max-width: 100vw;
}
.body {
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.whiteText {
  position: absolute;
  color: white;
}
.greyOverlay {
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: #6e6a69;
  height: 100vh;
  width: 100vw;
  opacity: 0.6;
}
.logoContainer {
  position: absolute;
  display: flex;
  justify-content: center;
}
.runvisorLanding {
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing1 {
  /* background-image: url("../img/landing/running-1705716_1920.jpg"); */
  background-image: url("../img/landing/running2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  padding: 0 0 0 0;
  zoom: 100%;
}
.landingTextContainer {
  display: flex;
  position: absolute;
  top: 0;
  height: 100vh;
  padding-right: 20vw;
  justify-content: flex-start;
  padding-left: 4vh;
  align-items: center;
  width: 100vw;
}
.landing1Text {
  position: absolute;
}
.landing2 {
  background-image: url("../img/landing/pexels-run-ffwpu-1571938.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-position: -500px 0px; */
  /* height: 100vh; */
  width: 100vw;
  padding: 0 0 0 0;
  /* zoom: 120%; */
  /* zoom: 100%; */
}
.landing2TextContainer {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  padding-right: 3vh;
  top: 3vh;
  color: white;
  width: 100vw;
  padding-top: 3vh;
}
.landing3 {
  background-image: url("../img/landing/road2.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: left; */
  background-position: -120px;
  height: 100vh;
  width: 100vw;
  padding: 0 0 0 0;
}
.hiddenElement {
  color: transparent;
}
.globalTextContainer {
  display: flex;
  position: absolute;
  top: 3vh;
  align-items: center;
  width: 100vw;
  justify-content: center;
}
.containerText1 {
  display: flex;
  width: 80vw;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.text1 {
  display: flex;
  line-height: 36px;
  font-size: 36px;
  color: black;
  text-align: center;
  justify-content: center;
  width: 85vw;
}

.text2 {
  display: flex;
  line-height: 18px;
  font-size: 18px;
  color: black;
  text-align: center;
  padding-top: 2vh;
  align-items: right;
  width: 80vw;
  justify-content: center;
}
.slideTrackerContainer {
  position: absolute;
  bottom: 4vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
}
.dotContainer {
  position: absolute;
  bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 75px;
}
.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  /* border: 2.5px solid black; */
  border-radius: 50%;
  display: flex;
  cursor: pointer;
}
.coloredDot {
  height: 15px;
  width: 15px;
  background-color: white;
  border: 2.5px solid #0061b5;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
}
.containerButtonAndText {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 30vh;
  align-items: center;
  width: 100vw;
  justify-content: space-around;
  text-align: center;
  vertical-align: middle;
}
.landing3TextContainer {
}
.landing3Button {
  display: flex;
  font-size: 24px;
  color: #91855d;
  width: 62vw;
  text-align: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0;
  /* border: 1px solid #999; */
  /* box-shadow: 1px 1px 1px grey; */
}
.landing3ExtraText {
  display: flex;
  width: 62vw;
  text-align: center;
  justify-content: center;
  padding-top: 2vh;
  color: black;
}
.landingButtonBackground {
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
  margin-top: 0px;
  margin-bottom: 0px;
}
.tickContainer {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  top: 0vh;
  z-index: 0;
}
.runvisorTickButton {
  display: flex;
  background-image: url("../img/landing/Tick_Mark_Circle_icon-icons.com_69145.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 47vw;
  width: 47vw;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.landingButtonContainer {
  display: flex;
  position: absolute;
  top: 0vh;
  align-items: center;
  width: 100vw;
  height: 90vh;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.titleContainer {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.titleButton {
  display: flex;
  color: white;
  width: 100vw;
  text-align: center;
  justify-content: center;
  padding-bottom: 3.5vh;
}
.subtitleButton {
  display: flex;
  color: #999999;
  width: 92vw;
  text-align: center;
  justify-content: center;
  padding-bottom: 0vh;
  padding-right: 10.5vw;
  padding-left: 10.5vw;
}
.imageButtonContainer {
  display: flex;
  width: 47vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.distance1 {
  display: flex;
  background-image: url("../img/landing/men-1245982_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.distance2 {
  display: flex;
  background-image: url("../img/landing/running-3684873_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.distance3 {
  display: flex;
  background-image: url("../img/landing/pexels-picography-4456.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.distance4 {
  display: flex;
  background-image: url("../img/landing/desert-2806435_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.landscape1 {
  display: flex;
  background-image: url("../img/landing/switzerland-862870_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.landscape2 {
  display: flex;
  background-image: url("../img/landing/south-africa-5048247_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.landscape3 {
  display: flex;
  background-image: url("../img/landing/runner-1814460_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.landscape4 {
  display: flex;
  background-image: url("../img/landing/desert-2806435_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.landingButtonTitle {
  color: white;
  padding-top: 1vh;
  padding-bottom: 4.2vh;
  font-size: 18px;
}
.bottomMenuContainer {
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  width: 100vw;
  justify-content: space-evenly;
  flex-direction: column;
  color: white;
}
.backSkipContainer {
  display: flex;
  align-items: center;
  z-index: 100;
  background-color: #f5f5f5;
  width: 100vw;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 2.0vh;
}
.backButton {
  display: flex;
  color: black;
  text-decoration: underline;
  padding-left: 5vw;
  cursor: pointer;
  font-size: 18px;
  /*font-weight: bold;*/
}
.skipButton {
  display: flex;
  color: black;
  text-decoration: underline;
  padding-right: 5vw;
  cursor: pointer;
  color: grey;
  font-size: 14px;

}
.nextButton {
  display: flex;
  height: 6vh;
  line-height: 6vh;
  font-size: 18px;
  width: calc(100vw - 0px);
  text-align: center;
  cursor: pointer;
  justify-content: center;
  background-color: white;
  /*box-shadow: 0;*/
  border: 1px solid #ccc;
  color: black;
  padding:0 0;
  margin-bottom: 1px;
}
.landingButtonSubtitle {
  font-size: 12px;
  padding: 0 16px;
}
.difference1 {
  display: flex;
  background-image: url("../img/landing/1024px-UK_traffic_sign_524.1.svg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 190px;
}
.difference2 {
  display: flex;
  background-image: url("../img/landing/680px-Italian_traffic_signs_-_salita_ripida.svg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 190px;
}
.difference3 {
  display: flex;
  background-image: url("../img/landing/680px-Italian_traffic_signs_-_strada_dissestata.svg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 190px;
}
.difference4 {
  display: flex;
  background-image: url("../img/landing/680px-Italian_traffic_signs_-_strada_piatta.svg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 190px;
}
.nearOrFar1 {
  display: flex;
  background-image: url("../img/landing/20km2nero (1).jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 190px;
}
.nearOrFar2 {
  display: flex;
  background-image: url("../img/landing/50km2nero.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 190px;
}
.nearOrFar3 {
  display: flex;
  background-image: url("../img/landing/100km2nero.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 190px;
}
.nearOrFar4 {
  display: flex;
  background-image: url("../img/landing/ovunquekm2nero.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 32vw;
  width: 32vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 190px;
}
.doItLater {
  display: flex;
  color: white;
  text-decoration: underline;
  padding-right: 0vw;
  cursor: pointer;
  padding-bottom: 2vh;
  font-size: 12px;
}
.positionTextContainer {
  position: absolute;
  height: 90vh;
  top: 0vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.positionImage {
  display: flex;
  background-image: url("../img/landing/iconfinder_icons_pin_1564524.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 0vw;
  height: 47vw;
  width: 47vw;
  top: 0px;
  left: 0px;
  max-width: 400px;
  max-height: 190px;
}
.positionTitle {
  display: flex;
  color: black;
  width: 100vw;
  text-align: center;
  justify-content: center;
  padding-bottom: 3.5vh;
  padding-top: 4vh;
}
.positionSubtitle {
  display: flex;
  color: #999999;
  width: 84vw;
  text-align: center;
  justify-content: center;
}
.loginContainer {
  position: absolute;
  top: 0vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.loginText {
  display: flex;
  color: #999999;
  width: 76vw;
  text-align: center;
  justify-content: center;
  padding-top: 12vh;
  padding-bottom: 3vh;
}
.loginButton {
  display: flex;
  height: 50px;
  line-height: 6vh;
  font-size: 18px;
  width: 76vw;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0;
  border: 1px solid #ccc;
  color: black;
  font-weight: bold;
  border-radius: 16px;
}
.loginOppure {
  display: flex;
  color: #999999;
  width: 76vw;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  padding-top: 3.5vh;
  padding-bottom: 3.5vh;
}
.whiteSpace {
  color: transparent;
  display: flex;
  padding-top: 3vh;
  padding-bottom: 0vh;
}
.loginTerms {
  display: flex;
  color: #999999;
  width: 76vw;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  padding-top: 6vh;
  /*padding-bottom: 27.5vh;*/
}
.backSkipContainerLogin {
  display: flex;
  position: absolute;
  bottom: 0vh;
  align-items: center;
  width: 100vw;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 0vh;
}
.containerThanks {
  position: absolute;
  top: 8vh;
  display: flex;
  width: 100vw;
  height: 84vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.thanksTitle {
  display: flex;
  color: black;
  width: 76vw;
  text-align: center;
  justify-content: center;
  font-size: 32px;
  /* padding-top: 5.5vh; */
  padding-bottom: 3.5vh;
  font-weight: bold;
}
.thanksSubtilte {
  display: flex;
  color: black;
  width: 76vw;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  padding-top: 3.5vh;
  padding-bottom: 3.5vh;
}
.runvisorTick {
  background-image: url("../img/loghi/loghi/runvisorTick.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 400px;
  max-height: 200px;
  margin-left: 0vw;
  height: 100px;
  width: 47vw;
  cursor: pointer;
  padding: 16px 0;
}
.thanksText {
  display: flex;
  color: black;
  width: 76vw;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  padding-top: 3.5vh;
  padding-bottom: 3.5vh;
}
.bottomContainerThanks {
  position: absolute;
  bottom: 0vh;
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.img1Thanks {
  display: flex;
  background-image: url("../img/landing/pexels-pixabay-221210.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 10vh;
  width: 33vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.img2Thanks {
  display: flex;
  background-image: url("../img/landing/pexels-pixabay-54326 (1).jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 10vh;
  width: 33vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
.img3Thanks {
  display: flex;
  background-image: url("../img/landing/runner-1814460_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 0vw;
  height: 10vh;
  width: 33vw;
  top: 0px;
  left: 0px;
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
}
