.carousel2 .slider-wrapper {
  border-radius: 15px;
  max-width: 86vw;
}
.carousel3 .slider-wrapper {
  width: 100vw;
  max-height: 45vh;
  -webkit-transition: all 1s ease;
}
.carousel4 .slider-wrapper {
  border-radius: 5px;
  max-width: 86vw;
}
.mapContainerSearch {
  width: auto;
  overflow: hidden;
  max-height: 94vh;
  -webkit-transition: all 1s ease;
}
.collapsed.mapContainerSearch {
  max-height: 40vh;
}

.plus {
  transition: 500ms ease-in-out;
}

.rotate {
  transform: rotate(45deg);
}

/*.expanded.slider-wrapper.carousel3{*/
/*max-height: 100vh;*/
/*}*/
.carousel4 .slider-wrapper {
  width: 100vw;
  max-height: calc(100vh - 100px);
  -webkit-transition: all 1s ease;
  border-radius: 16px;
}

.imgCarousel {
  object-fit: cover;
  -webkit-transition: all 1s ease;
  height: 32vh;
}
.expanded.imgCarousel {
  object-fit: contain;
  height: calc(100vh - max(50px, 5vh));
}

.elevationProfile {
  max-height: 30vh;
  -webkit-transition: all 1s ease;
}
.expand.elevationProfile {
  max-height: 100vh;
}
.iconSingleResult {
  cursor: pointer;
  -webkit-transition: all 1s ease;
}
.opacityDown {
  -webkit-transition: all 1s ease;
  opacity: 0.2;
}
.circleTransition {
  -webkit-transition: all 1s ease;
}
.circleShadow {
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
}
.radioButtonStyle {
  height: 10px;
  fontsize: 16px;
}
.filter {
  -webkit-transition: all 1s ease;
}
.textShrink {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.input-range__slider {
  background: rgb(149, 214, 10) !important;
  border: 1px solid rgb(149, 214, 10) !important;
}
.input-range__track--active {
  background: rgb(149, 214, 10) !important;
}

.textShrink6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* number of lines to show */
  -webkit-box-orient: vertical;
}